import {
	ADD_EDITOR_TO_SURVEY,
	ADD_EDITOR_TO_SURVEY_FAIL,
	ADD_EDITOR_TO_SURVEY_SUCCESS,
	ADD_VIEWER_TO_SURVEY,
	ADD_VIEWER_TO_SURVEY_FAIL,
	ADD_VIEWER_TO_SURVEY_SUCCESS,
	CHECK_USER_PASSWORD,
	CHECK_USER_PASSWORD_FAIL,
	CHECK_USER_PASSWORD_SUCCESS,
	CREATE_CONTENT,
	CREATE_CONTENT_FAIL,
	CREATE_CONTENT_SUCCESS,
	CREATE_CUSTOMER,
	CREATE_CUSTOMER_FAIL,
	CREATE_CUSTOMER_SUCCESS,
	CREATE_MAILING_LIST,
	CREATE_MAILING_LIST_FAIL,
	CREATE_MAILING_LIST_SUCCESS,
	CREATE_MULTIPLE_CUSTOMERS,
	CREATE_MULTIPLE_CUSTOMERS_FAIL,
	CREATE_MULTIPLE_CUSTOMERS_SUCCESS,
	CREATE_NEW_ORGANISATION_USER,
	CREATE_NEW_ORGANISATION_USER_FAIL,
	CREATE_NEW_ORGANISATION_USER_SUCCESS,
	CREATE_NOTIFICATION,
	CREATE_NOTIFICATION_FAIL,
	CREATE_NOTIFICATION_SUCCESS,
	CREATE_ORGANISATION,
	CREATE_ORGANISATION_FAIL,
	CREATE_ORGANISATION_SUCCESS,
	CREATE_SURVEY,
	CREATE_SURVEY_FAIL,
	CREATE_SURVEY_SUCCESS,
	DELETE_CUSTOMER,
	DELETE_CUSTOMER_FAIL,
	DELETE_CUSTOMER_SUCCESS,
	DELETE_MAILING_LIST,
	DELETE_MAILING_LIST_FAIL,
	DELETE_MAILING_LIST_SUCCESS,
	DELETE_SURVEY,
	DELETE_SURVEY_FAIL,
	DELETE_SURVEY_SUCCESS,
	EDIT_NOTIFICATION,
	EDIT_NOTIFICATION_FAIL,
	EDIT_NOTIFICATION_SUCCESS,
	GET_ALL_CATEGORIES,
	GET_ALL_CATEGORIES_FAIL,
	GET_ALL_CATEGORIES_SUCCESS,
	GET_ALL_NOTIFICATIONS,
	GET_ALL_NOTIFICATIONS_FAIL,
	GET_ALL_NOTIFICATIONS_SUCCESS,
	GET_ALL_ORGANISATIONS,
	GET_ALL_ORGANISATIONS_FAIL,
	GET_ALL_ORGANISATIONS_SUCCESS,
	GET_COMPARISON_BY_SURVEY,
	GET_COMPARISON_BY_SURVEY_FAIL,
	GET_COMPARISON_BY_SURVEY_SUCCESS,
	GET_COMPARISON_BY_ADVANCE_FILTER,
	GET_COMPARISON_BY_ADVANCE_FILTER_FAIL,
	GET_COMPARISON_BY_ADVANCE_FILTER_SUCCESS,
	GET_COMPARISON_BY_ADVANCE_COMPARISON,
	GET_COMPARISON_BY_ADVANCE_COMPARISON_FAIL,
	GET_COMPARISON_BY_ADVANCE_COMPARISON_SUCCESS,
	GET_COMPARISON_BY_OWN_SURVEY,
	GET_COMPARISON_BY_OWN_SURVEY_FAIL,
	GET_COMPARISON_BY_OWN_SURVEY_SUCCESS,
	GET_CURRENT_USER,
	GET_CURRENT_USER_FAIL,
	GET_CURRENT_USER_SUCCESS,
	GET_DEFAULT_TEMPLATES,
	GET_DEFAULT_TEMPLATES_FAIL,
	GET_DEFAULT_TEMPLATES_SUCCESS,
	GET_MAILING_LIST,
	GET_MAILING_LIST_BY_ID,
	GET_MAILING_LIST_BY_ID_FAIL,
	GET_MAILING_LIST_BY_ID_SUCCESS,
	GET_MAILING_LIST_FAIL,
	GET_MAILING_LIST_SUCCESS,
	GET_METER_COMPARISON,
	GET_METER_COMPARISON_FAIL,
	GET_METER_COMPARISON_SUCCESS,
	GET_ORGANISATION_BY_ID,
	GET_ORGANISATION_BY_ID_FAIL,
	GET_ORGANISATION_BY_ID_SUCCESS,
	GET_QUESTION_TYPES,
	GET_QUESTION_TYPES_FAIL,
	GET_QUESTION_TYPES_SUCCESS,
	GET_RESULT_BY_SURVEY_ID,
	GET_RESULT_BY_SURVEY_ID_FAIL,
	GET_RESULT_BY_SURVEY_ID_SUCCESS,
	GET_SECTION_FILTER_RESULT,
	GET_SECTION_FILTER_RESULT_FAIL,
	GET_SECTION_FILTER_RESULT_SUCCESS,
	GET_SINGLE_CONTENT,
	GET_SINGLE_CONTENT_FAIL,
	GET_SINGLE_CONTENT_SUCCESS,
	GET_SINGLE_SURVEY,
	GET_SINGLE_SURVEY_FAIL,
	GET_SINGLE_SURVEY_SUCCESS,
	GET_SURVEY_BY_PRIVATE_LINK,
	GET_SURVEY_BY_PRIVATE_LINK_FAIL,
	GET_SURVEY_BY_PRIVATE_LINK_SUCCESS,
	GET_SURVEY_BY_PUBLIC_LINK,
	GET_SURVEY_BY_PUBLIC_LINK_FAIL,
	GET_SURVEY_BY_PUBLIC_LINK_SUCCESS,
	GET_SURVEY_COMPARISON,
	GET_SURVEY_COMPARISON_FAIL,
	GET_SURVEY_COMPARISON_SUCCESS,
	GET_USER_SURVEY_RESULTS,
	GET_USER_SURVEY_RESULTS_FAIL,
	GET_USER_SURVEY_RESULTS_SUCCESS,
	GET_USER_SURVEYS,
	GET_USER_SURVEYS_FAIL,
	GET_USER_SURVEYS_SUCCESS,
	POST_ANSWERS,
	POST_ANSWERS_FAIL,
	POST_ANSWERS_SUCCESS,
	PUBLISH_SURVEY,
	PUBLISH_SURVEY_FAIL,
	PUBLISH_SURVEY_SUCCESS,
	REQUEST_NEW_ORGANISATION,
	REQUEST_NEW_ORGANISATION_FAIL,
	REQUEST_NEW_ORGANISATION_SUCCESS,
	SEND_MORE_MAILING_LIST,
	SEND_MORE_MAILING_LIST_FAIL,
	SEND_MORE_MAILING_LIST_SUCCESS,
	SEND_SURVEY_REMINDER,
	SEND_SURVEY_REMINDER_FAIL,
	SEND_SURVEY_REMINDER_SUCCESS,
	UPDATE_CONTENT,
	UPDATE_CONTENT_FAIL,
	UPDATE_CONTENT_SUCCESS,
	UPDATE_CUSTOMER,
	UPDATE_CUSTOMER_FAIL,
	UPDATE_CUSTOMER_SUCCESS,
	UPDATE_MAILING_LIST,
	UPDATE_MAILING_LIST_FAIL,
	UPDATE_MAILING_LIST_SUCCESS,
	UPDATE_ORGANISATION,
	UPDATE_ORGANISATION_FAIL,
	UPDATE_ORGANISATION_SUCCESS,
	UPDATE_OTHER_USER,
	UPDATE_OTHER_USER_FAIL,
	UPDATE_OTHER_USER_SUCCESS,
	UPDATE_PASSWORD,
	UPDATE_PASSWORD_FAIL,
	UPDATE_PASSWORD_SUCCESS,
	UPDATE_SURVEY,
	UPDATE_SURVEY_FAIL,
	UPDATE_SURVEY_SUCCESS,
	UPDATE_USER,
	UPDATE_USER_FAIL,
	UPDATE_USER_SUCCESS,
	CREATE_QUESTION,
	CREATE_QUESTION_FAIL,
	CREATE_QUESTION_SUCCESS,
	RESULT_GET_SURVEY_BY_TITLE,
	RESULT_GET_SURVEY_BY_TITLE_SUCCESS,
	RESULT_GET_SURVEY_BY_TITLE_FAIL,
	SURVEY_GET_SURVEY_BY_TITLE,
	SURVEY_GET_SURVEY_BY_TITLE_SUCCESS,
	SURVEY_GET_SURVEY_BY_TITLE_FAIL,
	GET_SURVEY_ANSWERS,
	GET_SURVEY_ANSWERS_SUCCESS,
	GET_SURVEY_ANSWERS_FAIL,
	GET_ALL_IMAGES,
	GET_ALL_IMAGES_SUCCESS,
	GET_ALL_IMAGES_FAIL,
	UPLOAD_IMAGE,
	UPLOAD_IMAGE_SUCCESS,
	UPLOAD_IMAGE_FAIL,
	GET_ORGANIZATION_SUB_ORG_STATUS,
	GET_ORGANIZATION_SUB_ORG_STATUS_SUCCESS,
	GET_ORGANIZATION_SUB_ORG_STATUS_FAIL,
	GET_ORGANISATION_RESULT,
	GET_ORGANISATION_RESULT_SUCCESS,
	GET_ORGANISATION_RESULT_FAIL,
	GET_ORGANISATION_SECTION_FILTER,
	GET_ORGANISATION_SECTION_FILTER_COMPARISON,
	GET_ORGANISATION_SECTION_FILTER_FAIL,
	GET_ORGANISATION_SECTION_FILTER_SUCCESS,
	GET_ORGANISATION_SECTION_FILTER_COMPARISON_FAIL,
	GET_ORGANISATION_SECTION_FILTER_COMPARISON_SUCCESS,
	GET_ORGANISATION_ADVANCED_FILTER_COMPARISON,
	GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_FAIL,
	GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_SUCCESS,
	GET_OWN_CATEGORIES,
	GET_OWN_CATEGORIES_FAIL,
	GET_OWN_CATEGORIES_SUCCESS,
	CREATE_SECTION_TRANSLATIONS,
	CREATE_CONTENT_TRANSLATIONS,
	CREATE_CONTENT_TRANSLATIONS_FAIL,
	CREATE_CONTENT_TRANSLATIONS_SUCCESS,
	CREATE_SECTION_TRANSLATIONS_FAIL,
	CREATE_SECTION_TRANSLATIONS_SUCCESS,
	UPDATE_SECTION_TRANSLATIONS,
	UPDATE_CONTENT_TRANSLATIONS,
	UPDATE_CONTENT_TRANSLATIONS_FAIL,
	UPDATE_CONTENT_TRANSLATIONS_SUCCESS,
	UPDATE_SECTION_TRANSLATIONS_FAIL,
	UPDATE_SECTION_TRANSLATIONS_SUCCESS,
	CREATE_METER_TEMPLATE,
	EDIT_METER_TEMPLATE,
	EDIT_METER_TEMPLATE_FAIL,
	EDIT_METER_TEMPLATE_SUCCESS,
	CREATE_METER_TEMPLATE_FAIL,
	CREATE_METER_TEMPLATE_SUCCESS,
	GET_ALL_SECTION_TEMPLATES_FAIL,
	GET_ALL_SECTION_TEMPLATES_SUCCESS,
	GET_ALL_SECTION_TEMPLATES,
	GET_ANALYZABLE_TEMPLATES,
	GET_ANALYZABLE_TEMPLATES_FAIL,
	GET_ANALYZABLE_TEMPLATES_SUCCESS,
	EDIT_METER_TEMPLATE_CATEGORIES_FAIL,
	EDIT_METER_TEMPLATE_CATEGORIES_SUCCESS,
	EDIT_METER_TEMPLATE_CATEGORIES,
	EDIT_METER_TEMPLATE_ORGANIZATION,
	EDIT_METER_TEMPLATE_ORGANIZATION_FAIL,
	EDIT_METER_TEMPLATE_ORGANIZATION_SUCCESS,
	POST_PRINT_OWN_ANSWERS,
	POST_PRINT_OWN_ANSWERS_FAIL,
	POST_PRINT_OWN_ANSWERS_SUCCESS,
	GET_COMBINE_BY_OWN_SURVEY,
	GET_COMBINE_BY_OWN_SURVEY_FAIL,
	GET_COMBINE_BY_OWN_SURVEY_SUCCESS
} from '../actions/types'

const initialState = {
	formAnswerLoading: false,
	formDashboardLoading: false,
	formTemplateLoading: false,
	formCategoryLoading: false,
	editSurveyInfoLoading: false,
	editSurveyThankYouLoading: false,
	organisationLoading: false,
	organisationAddLoading: false,
	organisationEditLoading: false,
	organisationUsersLoading: false,
	profileLoading: false,
	recipientAddLoading: false,
	recipientListLoading: false,
	recipientSummaryLoading: false,
	registerLoading: false,
	resetPasswordLoading: false,
	resultDetailLoading: false,
	resultSummaryLoading: false,
	surveySummaryLoading: false,
	newSurveyLoading: false,
	createNotificationLoading: false,
	showNotificationLoading: false,
	imageBankLoading: false,
	organizationAnswersLoading: false,
	translationsLoading: false
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SINGLE_SURVEY:
			return { ...state, formAnswerLoading: true }
		case GET_SINGLE_SURVEY_FAIL:
			return { ...state, formAnswerLoading: false }
		case GET_SINGLE_SURVEY_SUCCESS:
			return { ...state, formAnswerLoading: false }
		case GET_SINGLE_CONTENT:
			return {
				...state,
				formAnswerLoading: true,
				resultSummaryLoading: true,
				surveySummaryLoading: true
			}
		case GET_SINGLE_CONTENT_FAIL:
			return {
				...state,
				formAnswerLoading: false,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case GET_SINGLE_CONTENT_SUCCESS:
			return {
				...state,
				formAnswerLoading: false,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case POST_ANSWERS:
		case POST_PRINT_OWN_ANSWERS:
			return { ...state, formAnswerLoading: true }
		case POST_ANSWERS_FAIL:
		case POST_PRINT_OWN_ANSWERS_FAIL:
			return { ...state, formAnswerLoading: false }
		case POST_ANSWERS_SUCCESS:
		case POST_PRINT_OWN_ANSWERS_SUCCESS:
			return { ...state, formAnswerLoading: false }
		case CREATE_CONTENT:
			return {
				...state,
				formDashboardLoading: true,
				resultSummaryLoading: true,
				surveySummaryLoading: true,
				editSurveyInfoLoading: true,
				formTemplateLoading: true,
				editSurveyThankYouLoading: true,
				formCategoryLoading: true
			}
		case CREATE_CONTENT_FAIL:
			return {
				...state,
				formDashboardLoading: false,
				resultSummaryLoading: false,
				surveySummaryLoading: false,
				editSurveyInfoLoading: false,
				formTemplateLoading: false,
				editSurveyThankYouLoading: false,
				formCategoryLoading: false
			}
		case CREATE_CONTENT_SUCCESS:
			return {
				...state,
				formDashboardLoading: false,
				resultSummaryLoading: false,
				surveySummaryLoading: false,
				editSurveyInfoLoading: false,
				formTemplateLoading: false,
				editSurveyThankYouLoading: false,
				formCategoryLoading: false
			}
		case CREATE_SURVEY:
			return {
				...state,
				formDashboardLoading: true,
				resultSummaryLoading: true,
				surveySummaryLoading: true,
				editSurveyInfoLoading: true,
				formTemplateLoading: true,
				editSurveyThankYouLoading: true,
				formCategoryLoading: true
			}
		case CREATE_SURVEY_FAIL:
			return {
				...state,
				formDashboardLoading: false,
				resultSummaryLoading: false,
				surveySummaryLoading: false,
				editSurveyInfoLoading: false,
				formTemplateLoading: false,
				editSurveyThankYouLoading: false,
				formCategoryLoading: false
			}
		case CREATE_SURVEY_SUCCESS:
			return {
				...state,
				formDashboardLoading: false,
				resultSummaryLoading: false,
				surveySummaryLoading: false,
				editSurveyInfoLoading: false,
				formTemplateLoading: false,
				editSurveyThankYouLoading: false,
				formCategoryLoading: false
			}
		case GET_QUESTION_TYPES:
			return { ...state, formTemplateLoading: true }
		case GET_QUESTION_TYPES_FAIL:
			return { ...state, formTemplateLoading: false }
		case GET_QUESTION_TYPES_SUCCESS:
			return { ...state, formTemplateLoading: false }
		case GET_DEFAULT_TEMPLATES:
			return { ...state, formTemplateLoading: true }
		case GET_DEFAULT_TEMPLATES_FAIL:
			return { ...state, formTemplateLoading: false }
		case GET_DEFAULT_TEMPLATES_SUCCESS:
			return { ...state, formTemplateLoading: false }
		case GET_ALL_ORGANISATIONS:
			return { ...state, organisationLoading: true }
		case GET_ALL_ORGANISATIONS_FAIL:
			return { ...state, organisationLoading: false }
		case GET_ALL_ORGANISATIONS_SUCCESS:
			return { ...state, organisationLoading: false }
		case CREATE_ORGANISATION:
			return { ...state, organisationAddLoading: true }
		case CREATE_ORGANISATION_FAIL:
			return { ...state, organisationAddLoading: false }
		case CREATE_ORGANISATION_SUCCESS:
			return { ...state, organisationAddLoading: false }
		case CREATE_NEW_ORGANISATION_USER:
			return {
				...state,
				organisationAddLoading: true,
				organisationUsersLoading: true
			}
		case CREATE_NEW_ORGANISATION_USER_FAIL:
			return {
				...state,
				organisationAddLoading: false,
				organisationUsersLoading: false
			}
		case CREATE_NEW_ORGANISATION_USER_SUCCESS:
			return {
				...state,
				organisationAddLoading: false,
				organisationUsersLoading: false
			}
		case UPDATE_ORGANISATION:
			return { ...state, organisationEditLoading: true }
		case UPDATE_ORGANISATION_FAIL:
			return { ...state, organisationEditLoading: false }
		case UPDATE_ORGANISATION_SUCCESS:
			return { ...state, organisationEditLoading: false }
		case GET_ORGANISATION_BY_ID:
			return {
				...state,
				organisationEditLoading: true,
				organisationUsersLoading: true
			}
		case GET_ORGANISATION_BY_ID_FAIL:
			return {
				...state,
				organisationEditLoading: false,
				organisationUsersLoading: false
			}
		case GET_ORGANISATION_BY_ID_SUCCESS:
			return {
				...state,
				organisationEditLoading: false,
				organisationUsersLoading: false
			}
		case GET_ORGANIZATION_SUB_ORG_STATUS:
			return {
				...state,
				organisationEditLoading: true,
				organisationUsersLoading: true
			}
		case GET_ORGANIZATION_SUB_ORG_STATUS_SUCCESS:
			return {
				...state,
				organisationEditLoading: false,
				organisationUsersLoading: false
			}
		case GET_ORGANIZATION_SUB_ORG_STATUS_FAIL:
			return {
				...state,
				organisationEditLoading: false,
				organisationUsersLoading: false
			}
		case UPDATE_OTHER_USER:
			return { ...state, organisationUsersLoading: true }
		case UPDATE_OTHER_USER_FAIL:
			return { ...state, organisationUsersLoading: false }
		case UPDATE_OTHER_USER_SUCCESS:
			return { ...state, organisationUsersLoading: false }
		case CHECK_USER_PASSWORD:
			return { ...state, profileLoading: true }
		case CHECK_USER_PASSWORD_FAIL:
			return { ...state, profileLoading: false }
		case CHECK_USER_PASSWORD_SUCCESS:
			return { ...state, profileLoading: false }
		case UPDATE_USER:
			return { ...state, profileLoading: true }
		case UPDATE_USER_FAIL:
			return { ...state, profileLoading: false }
		case UPDATE_USER_SUCCESS:
			return { ...state, profileLoading: false }
		case CREATE_MAILING_LIST:
			return {
				...state,
				recipientAddLoading: true,
				recipientSummaryLoading: true
			}
		case CREATE_MAILING_LIST_FAIL:
			return {
				...state,
				recipientAddLoading: false,
				recipientSummaryLoading: false
			}
		case CREATE_MAILING_LIST_SUCCESS:
			return {
				...state,
				recipientAddLoading: false,
				recipientSummaryLoading: false
			}
		case CREATE_MULTIPLE_CUSTOMERS:
			return { ...state, recipientListLoading: true }
		case CREATE_MULTIPLE_CUSTOMERS_FAIL:
			return { ...state, recipientListLoading: false }
		case CREATE_MULTIPLE_CUSTOMERS_SUCCESS:
			return { ...state, recipientListLoading: false }
		case CREATE_CUSTOMER:
			return { ...state, recipientListLoading: true }
		case CREATE_CUSTOMER_FAIL:
			return { ...state, recipientListLoading: false }
		case CREATE_CUSTOMER_SUCCESS:
			return { ...state, recipientListLoading: false }
		case DELETE_CUSTOMER:
			return { ...state, recipientListLoading: true }
		case DELETE_CUSTOMER_FAIL:
			return { ...state, recipientListLoading: false }
		case DELETE_CUSTOMER_SUCCESS:
			return { ...state, recipientListLoading: false }
		case GET_MAILING_LIST_BY_ID:
			return { ...state, recipientListLoading: true }
		case GET_MAILING_LIST_BY_ID_FAIL:
			return { ...state, recipientListLoading: false }
		case GET_MAILING_LIST_BY_ID_SUCCESS:
			return { ...state, recipientListLoading: false }
		case UPDATE_MAILING_LIST:
			return { ...state, recipientListLoading: true }
		case UPDATE_MAILING_LIST_FAIL:
			return { ...state, recipientListLoading: false }
		case UPDATE_MAILING_LIST_SUCCESS:
			return { ...state, recipientListLoading: false }
		case UPDATE_CUSTOMER:
			return { ...state, recipientListLoading: true }
		case UPDATE_CUSTOMER_FAIL:
			return { ...state, recipientListLoading: false }
		case UPDATE_CUSTOMER_SUCCESS:
			return { ...state, recipientListLoading: false }
		case GET_MAILING_LIST:
		case GET_MAILING_LIST_BY_ID:
			return {
				...state,
				recipientSummaryLoading: true,
				formDashboardLoading: true,
				editSurveyInfoLoading: true
			}
		case GET_MAILING_LIST_BY_ID_FAIL:
		case GET_MAILING_LIST_BY_ID_SUCCESS:
		case GET_MAILING_LIST_FAIL:
		case GET_MAILING_LIST_SUCCESS:
			return {
				...state,
				recipientSummaryLoading: false,
				formDashboardLoading: false,
				editSurveyInfoLoading: false
			}

		case DELETE_MAILING_LIST:
			return { ...state, recipientSummaryLoading: true }
		case DELETE_MAILING_LIST_FAIL:
		case DELETE_MAILING_LIST_SUCCESS:
			return { ...state, recipientSummaryLoading: false }

		case REQUEST_NEW_ORGANISATION:
			return { ...state, registerLoading: true }
		case REQUEST_NEW_ORGANISATION_FAIL:
			return { ...state, registerLoading: false }
		case REQUEST_NEW_ORGANISATION_SUCCESS:
			return { ...state, registerLoading: false }
		case GET_CURRENT_USER:
			return { ...state, resetPasswordLoading: true }
		case GET_CURRENT_USER_FAIL:
			return { ...state, resetPasswordLoading: false }
		case GET_CURRENT_USER_SUCCESS:
			return { ...state, resetPasswordLoading: false }
		case UPDATE_PASSWORD:
			return { ...state, resetPasswordLoading: true }
		case UPDATE_PASSWORD_FAIL:
			return { ...state, resetPasswordLoading: false }
		case UPDATE_PASSWORD_SUCCESS:
			return { ...state, resetPasswordLoading: false }
		case GET_RESULT_BY_SURVEY_ID:
			return { ...state, resultDetailLoading: true }
		case GET_RESULT_BY_SURVEY_ID_FAIL:
			return { ...state, resultDetailLoading: false }
		case GET_RESULT_BY_SURVEY_ID_SUCCESS:
			return { ...state, resultDetailLoading: false }
		case GET_SURVEY_COMPARISON:
			return { ...state, resultDetailLoading: true }
		case GET_SURVEY_COMPARISON_FAIL:
			return { ...state, resultDetailLoading: false }
		case GET_SURVEY_COMPARISON_SUCCESS:
			return { ...state, resultDetailLoading: false }
		case GET_ORGANISATION_RESULT:
			return { ...state, resultDetailLoading: true }
		case GET_ORGANISATION_RESULT_FAIL:
			return { ...state, resultDetailLoading: false }
		case GET_ORGANISATION_RESULT_SUCCESS:
			return { ...state, resultDetailLoading: false }
		case GET_ORGANISATION_SECTION_FILTER:
			return { ...state, resultDetailLoading: true }
		case GET_ORGANISATION_SECTION_FILTER_FAIL:
			return { ...state, resultDetailLoading: false }
		case GET_ORGANISATION_SECTION_FILTER_SUCCESS:
			return { ...state, resultDetailLoading: false }
		case GET_ORGANISATION_ADVANCED_FILTER_COMPARISON:
		case GET_ORGANISATION_SECTION_FILTER_COMPARISON:
			return { ...state, resultDetailLoading: true }
		case GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_FAIL:
		case GET_ORGANISATION_SECTION_FILTER_COMPARISON_FAIL:
			return { ...state, resultDetailLoading: false }
		case GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_SUCCESS:
		case GET_ORGANISATION_SECTION_FILTER_COMPARISON_SUCCESS:
			return { ...state, resultDetailLoading: false }
		case UPDATE_SURVEY:
			return {
				...state,
				resultSummaryLoading: true,
				surveySummaryLoading: true,
				formCategoryLoading: true,
				editSurveyInfoLoading: true,
				editSurveyThankYouLoading: true,
				formTemplateLoading: true,
				formDashboardLoading: true
			}
		case UPDATE_SURVEY_FAIL:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false,
				formCategoryLoading: false,
				editSurveyInfoLoading: false,
				editSurveyThankYouLoading: false,
				formTemplateLoading: false,
				formDashboardLoading: false
			}
		case UPDATE_SURVEY_SUCCESS:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false,
				formCategoryLoading: false,
				editSurveyInfoLoading: false,
				editSurveyThankYouLoading: false,
				formTemplateLoading: false,
				formDashboardLoading: false
			}
		case UPDATE_CONTENT:
			return {
				...state,
				formCategoryLoading: true,
				formTemplateLoading: true,
				editSurveyInfoLoading: true,
				editSurveyThankYouLoading: true,
				formDashboardLoading: true
			}
		case UPDATE_CONTENT_FAIL:
			return {
				...state,
				formCategoryLoading: false,
				formTemplateLoading: false,
				editSurveyInfoLoading: false,
				editSurveyThankYouLoading: false,
				formDashboardLoading: false
			}
		case UPDATE_CONTENT_SUCCESS:
			return {
				...state,
				formCategoryLoading: false,
				formTemplateLoading: false,
				editSurveyInfoLoading: false,
				editSurveyThankYouLoading: false,
				formDashboardLoading: false
			}
		case GET_USER_SURVEY_RESULTS:
			return { ...state, resultSummaryLoading: true }
		case GET_USER_SURVEY_RESULTS_FAIL:
			return { ...state, resultSummaryLoading: false }
		case GET_USER_SURVEY_RESULTS_SUCCESS:
			return { ...state, resultSummaryLoading: false }
		case GET_USER_SURVEYS:
			return { ...state, surveySummaryLoading: true }
		case GET_USER_SURVEYS_FAIL:
			return { ...state, surveySummaryLoading: false }
		case GET_USER_SURVEYS_SUCCESS:
			return { ...state, surveySummaryLoading: false }
		case GET_SURVEY_BY_PUBLIC_LINK:
			return { ...state, formAnswerLoading: true }
		case GET_SURVEY_BY_PUBLIC_LINK_FAIL:
			return { ...state, formAnswerLoading: false }
		case GET_SURVEY_BY_PUBLIC_LINK_SUCCESS:
			return { ...state, formAnswerLoading: false }
		case GET_SURVEY_BY_PRIVATE_LINK:
			return { ...state, formAnswerLoading: true }
		case GET_SURVEY_BY_PRIVATE_LINK_FAIL:
			return { ...state, formAnswerLoading: false }
		case GET_SURVEY_BY_PRIVATE_LINK_SUCCESS:
			return { ...state, formAnswerLoading: false }
		case GET_OWN_CATEGORIES:
			return { ...state, newSurveyLoading: true }
		case GET_OWN_CATEGORIES_FAIL:
			return { ...state, newSurveyLoading: false }
		case GET_OWN_CATEGORIES_SUCCESS:
			return { ...state, newSurveyLoading: false }
		case DELETE_SURVEY:
			return {
				...state,
				surveySummaryLoading: true,
				resultSummaryLoading: true
			}
		case DELETE_SURVEY_FAIL:
			return {
				...state,
				surveySummaryLoading: false,
				resultSummaryLoading: false
			}
		case DELETE_SURVEY_SUCCESS:
			return {
				...state,
				surveySummaryLoading: false,
				resultSummaryLoading: false
			}
		case PUBLISH_SURVEY:
			return { ...state, formDashboardLoading: true }
		case PUBLISH_SURVEY_FAIL:
			return { ...state, formDashboardLoading: false }
		case PUBLISH_SURVEY_SUCCESS:
			return { ...state, formDashboardLoading: false }
		case GET_SECTION_FILTER_RESULT:
			return { ...state, resultDetailLoading: true }
		case GET_SECTION_FILTER_RESULT_FAIL:
			return { ...state, resultDetailLoading: false }
		case GET_SECTION_FILTER_RESULT_SUCCESS:
			return { ...state, resultDetailLoading: false }
		case SEND_MORE_MAILING_LIST:
			return { ...state, formDashboardLoading: true }
		case SEND_MORE_MAILING_LIST_FAIL:
			return { ...state, formDashboardLoading: false }
		case SEND_MORE_MAILING_LIST_SUCCESS:
			return { ...state, formDashboardLoading: false }
		case SEND_SURVEY_REMINDER:
			return { ...state, formDashboardLoading: true }
		case SEND_SURVEY_REMINDER_FAIL:
			return { ...state, formDashboardLoading: false }
		case SEND_SURVEY_REMINDER_SUCCESS:
			return { ...state, formDashboardLoading: false }
		case GET_COMPARISON_BY_SURVEY:
		case GET_COMPARISON_BY_ADVANCE_FILTER:
		case GET_COMPARISON_BY_ADVANCE_COMPARISON:
		case GET_COMPARISON_BY_OWN_SURVEY:
		case GET_COMBINE_BY_OWN_SURVEY:
			return { ...state, resultDetailLoading: true }
		case GET_COMPARISON_BY_SURVEY_FAIL:
		case GET_COMPARISON_BY_OWN_SURVEY_FAIL:
		case GET_COMBINE_BY_OWN_SURVEY_FAIL:
			return { ...state, resultDetailLoading: false }
		case GET_COMPARISON_BY_SURVEY_SUCCESS:
		case GET_COMPARISON_BY_OWN_SURVEY_SUCCESS:
		case GET_COMBINE_BY_OWN_SURVEY_SUCCESS:
			return { ...state, resultDetailLoading: false }
			return { ...state, resultDetailLoading: true }
			return { ...state, resultDetailLoading: false }
			return { ...state, resultDetailLoading: false }
		case GET_METER_COMPARISON:
			return { ...state, resultDetailLoading: true }
		case GET_COMPARISON_BY_SURVEY_FAIL:
		case GET_COMPARISON_BY_ADVANCE_FILTER_FAIL:
		case GET_COMPARISON_BY_ADVANCE_COMPARISON_FAIL:
		case GET_METER_COMPARISON_FAIL:
			return { ...state, resultDetailLoading: false }
		case GET_COMPARISON_BY_SURVEY_SUCCESS:
		case GET_COMPARISON_BY_ADVANCE_FILTER_SUCCESS:
		case GET_COMPARISON_BY_ADVANCE_COMPARISON_SUCCESS:
		case GET_METER_COMPARISON_SUCCESS:
			return { ...state, resultDetailLoading: false }
		case ADD_VIEWER_TO_SURVEY:
			return {
				...state,
				resultSummaryLoading: true,
				surveySummaryLoading: true
			}
		case ADD_VIEWER_TO_SURVEY_FAIL:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case ADD_VIEWER_TO_SURVEY_SUCCESS:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case ADD_EDITOR_TO_SURVEY:
			return {
				...state,
				resultSummaryLoading: true,
				surveySummaryLoading: true
			}
		case ADD_EDITOR_TO_SURVEY_FAIL:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case ADD_EDITOR_TO_SURVEY_SUCCESS:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case CREATE_NOTIFICATION:
			return { ...state, createNotificationLoading: true }
		case CREATE_NOTIFICATION_FAIL:
			return { ...state, createNotificationLoading: false }
		case CREATE_NOTIFICATION_SUCCESS:
			return { ...state, createNotificationLoading: false }
		case GET_ALL_NOTIFICATIONS:
			return { ...state, showNotificationLoading: true }
		case GET_ALL_NOTIFICATIONS_FAIL:
			return { ...state, showNotificationLoading: false }
		case GET_ALL_NOTIFICATIONS_SUCCESS:
			return { ...state, showNotificationLoading: false }
		case EDIT_NOTIFICATION:
			return { ...state, createNotificationLoading: true }
		case EDIT_NOTIFICATION_FAIL:
			return { ...state, createNotificationLoading: false }
		case EDIT_NOTIFICATION_SUCCESS:
			return { ...state, createNotificationLoading: false }
		case CREATE_QUESTION:
			return { ...state, formTemplateLoading: true }
		case CREATE_QUESTION_FAIL:
		case CREATE_QUESTION_SUCCESS:
			return { ...state, formTemplateLoading: false }
		case SURVEY_GET_SURVEY_BY_TITLE:
			return {
				...state,
				resultSummaryLoading: true,
				surveySummaryLoading: true
			}
		case SURVEY_GET_SURVEY_BY_TITLE_SUCCESS:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case SURVEY_GET_SURVEY_BY_TITLE_FAIL:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case RESULT_GET_SURVEY_BY_TITLE:
			return {
				...state,
				resultSummaryLoading: true,
				surveySummaryLoading: true
			}
		case RESULT_GET_SURVEY_BY_TITLE_SUCCESS:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case RESULT_GET_SURVEY_BY_TITLE_FAIL:
			return {
				...state,
				resultSummaryLoading: false,
				surveySummaryLoading: false
			}
		case GET_ALL_IMAGES:
		case UPLOAD_IMAGE:
			return {
				...state,
				imageBankLoading: true
			}
		case GET_ALL_IMAGES_SUCCESS:
		case UPLOAD_IMAGE_SUCCESS:
			return {
				...state,
				imageBankLoading: false
			}
		case GET_ALL_IMAGES_FAIL:
		case UPLOAD_IMAGE_FAIL:
			return {
				...state,
				imageBankLoading: false
			}
		case GET_ORGANISATION_RESULT:
			return {
				...state,
				organizationAnswersLoading: true
			}
		case GET_ORGANISATION_RESULT_SUCCESS:
		case GET_ORGANISATION_RESULT_FAIL:
			return {
				...state,
				organizationAnswersLoading: false
			}
		case CREATE_SECTION_TRANSLATIONS:
		case CREATE_CONTENT_TRANSLATIONS:
		case UPDATE_SECTION_TRANSLATIONS:
		case CREATE_METER_TEMPLATE:
		case EDIT_METER_TEMPLATE:
		case UPDATE_CONTENT_TRANSLATIONS:
		case GET_ANALYZABLE_TEMPLATES:
		case GET_ALL_SECTION_TEMPLATES:
		case EDIT_METER_TEMPLATE_CATEGORIES:
		case GET_ALL_ORGANISATIONS:
		case EDIT_METER_TEMPLATE_ORGANIZATION: {
			return { ...state, formTemplateLoading: true }
		}
		case EDIT_METER_TEMPLATE_FAIL:
		case EDIT_METER_TEMPLATE_SUCCESS:
		case CREATE_METER_TEMPLATE_FAIL:
		case CREATE_METER_TEMPLATE_SUCCESS:
		case CREATE_CONTENT_TRANSLATIONS_FAIL:
		case CREATE_CONTENT_TRANSLATIONS_SUCCESS:
		case CREATE_SECTION_TRANSLATIONS_FAIL:
		case CREATE_SECTION_TRANSLATIONS_SUCCESS:
		case UPDATE_CONTENT_TRANSLATIONS_FAIL:
		case UPDATE_CONTENT_TRANSLATIONS_SUCCESS:
		case UPDATE_SECTION_TRANSLATIONS_FAIL:
		case UPDATE_SECTION_TRANSLATIONS_SUCCESS:
		case GET_ALL_SECTION_TEMPLATES_FAIL:
		case GET_ALL_SECTION_TEMPLATES_SUCCESS:
		case GET_ANALYZABLE_TEMPLATES_FAIL:
		case GET_ANALYZABLE_TEMPLATES_SUCCESS:
		case EDIT_METER_TEMPLATE_CATEGORIES_FAIL:
		case EDIT_METER_TEMPLATE_CATEGORIES_SUCCESS:
		case GET_ALL_ORGANISATIONS_FAIL:
		case GET_ALL_ORGANISATIONS_SUCCESS:
		case EDIT_METER_TEMPLATE_ORGANIZATION_FAIL:
		case EDIT_METER_TEMPLATE_ORGANIZATION_SUCCESS: {
			return { ...state, formTemplateLoading: false }
		}
		default:
			return state
	}
}

export default reducer

export const loadingSelector = {
	formAnswerLoading: (state = initialState) => state.formAnswerLoading,
	formDashboardLoading: (state = initialState) => state.formDashboardLoading,
	formTemplateLoading: (state = initialState) => state.formTemplateLoading,
	formCategoryLoading: (state = initialState) => state.formCategoryLoading,
	editSurveyInfoLoading: (state = initialState) => state.editSurveyInfoLoading,
	editSurveyThankYouLoading: (state = initialState) =>
		state.editSurveyThankYouLoading,
	organisationLoading: (state = initialState) => state.organisationLoading,
	organisationAddLoading: (state = initialState) =>
		state.organisationAddLoading,
	organisationEditLoading: (state = initialState) =>
		state.organisationEditLoading,
	organisationUsersLoading: (state = initialState) =>
		state.organisationUsersLoading,
	profileLoading: (state = initialState) => state.profileLoading,
	recipientAddLoading: (state = initialState) => state.recipientAddLoading,
	recipientListLoading: (state = initialState) => state.recipientListLoading,
	recipientSummaryLoading: (state = initialState) =>
		state.recipientSummaryLoading,
	registerLoading: (state = initialState) => state.registerLoading,
	resetPasswordLoading: (state = initialState) => state.resetPasswordLoading,
	resultDetailLoading: (state = initialState) => state.resultDetailLoading,
	resultSummaryLoading: (state = initialState) => state.resultSummaryLoading,
	surveySummaryLoading: (state = initialState) => state.surveySummaryLoading,
	newSurveyLoading: (state = initialState) => state.newSurveyLoading,
	createNotificationLoading: (state = initialState) =>
		state.createNotificationLoading,
	showNotificationLoading: (state = initialState) =>
		state.showNotificationLoading,
	imageBankLoading: (state = initialState) => state.imageBankLoading,
	organizationAnswersLoading: (state = initialState) =>
		state.organizationAnswersLoading
}
