/**
 * Main container
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { hot } from 'react-hot-loader/root'
import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import '../node_modules/regenerator-runtime/runtime'
import * as Sentry from '@sentry/browser'
// actions
import { clearErrorMessage } from 'actions'
// Hooks
import ScrollToTop from './shared/hooks/ScrollToTop'
// components
const ErrorMessage = lazy(() => import('./shared/components/ErrorMessage'))
const SuccessMessage = lazy(() => import('./shared/components/SuccessMessage'))
const CoreLayout = lazy(() => import('./shared/layout/CoreLayout'))
const ErrorBoundary = lazy(() => import('./hoc/ErrorBoundary'))
const NewBuildCheck = lazy(() => import('./hoc/NewBuildCheck'))
const TermsModal = lazy(() => import('./pages/Register/components/TermsModal'))

import { fileSelector, isAuthenticated } from './reducers'
const UnauthenticatedRouter = lazy(() =>
	import('./routers/UnauthenticatedRouter')
)
const AuthenticatedRouter = lazy(() => import('./routers/AuthenticatedRouter'))
const ProgressMessage = lazy(() =>
	import('./shared/components/ProgressMessage')
)
import { getUnreadNotifications } from './actions/notification'
import { userSelector } from './reducers/user'
import { updateUser, verifyBearer } from './actions/user'
// Context
import { PrintStateContext } from './shared/context/printState'

class App extends Component {
	state = {
		isTermOfUseAgreed: true
	}

	async componentDidMount() {
		// clear all error messages
		this.props.dispatch(clearErrorMessage())
		if (this.props.isAuthenticated) {
			this.props.dispatch(getUnreadNotifications())
			const res = await this.props.dispatch(verifyBearer())
			if (res.payload && res.payload.status === 200) {
				const termsAccepted = res.payload.data.termsAccepted
				if (termsAccepted == null) {
					this.setState({
						isTermOfUseAgreed: false
					})
				}
			}
		}
		window.addEventListener('keydown', this._handleTab)
	}

	async componentDidUpdate(prevProps) {
		if (
			this.props.isAuthenticated &&
			this.props.termsAccepted == null &&
			this.props.isAuthenticated !== prevProps.isAuthenticated
		) {
			this.setState({
				isTermOfUseAgreed: false
			})
		}

		if (this.props.isAuthenticated && this.props.organisationDomain === null) {
			try {
				await this.props.dispatch(verifyBearer())
				throw new Error('Organisation domain is null')
			} catch (e) {
				Sentry.captureException(e)
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this._handleTab)
		window.removeEventListener('mousedown', this._handleMouseDownOnce)
	}

	_handleTab = e => {
		if (e.keyCode === 9) {
			// the "I am a keyboard user" key
			document.body.classList.add('user-is-tabbing')
			window.removeEventListener('keydown', this._handleTab)
			window.addEventListener('mousedown', this._handleMouseDownOnce)
		}
	}

	_handleMouseDownOnce() {
		document.body.classList.remove('user-is-tabbing')
		window.removeEventListener('mousedown', this._handleMouseDownOnce)
		window.addEventListener('keydown', this._handleTab)
	}

	agreeTermOfUse = () => {
		this.setState({
			isTermOfUseAgreed: true
		})
		const termsAccepted = new Date().toLocaleString('en-US', {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric'
		})
		this.props.dispatch(
			updateUser({
				termsAccepted
			})
		)
	}

	render() {
		const pathNameList = window.location.pathname.split('/')
		const currentPage = [pathNameList[1]]
		if (pathNameList.indexOf('screenshot') !== -1) {
			currentPage.push('screenshot')
		} else if (pathNameList.indexOf('pdf') !== -1) {
			currentPage.push('pdf')
		}
		return (
			<Suspense fallback={<div>Loading</div>}>
				<ErrorBoundary>
					<div className={`app__content page-${currentPage.join('-')}`}>
						<Helmet titleTemplate="%s - Pokka" defaultTitle="Pokka">
							<meta name="description" content="Awesome Pokka" />
						</Helmet>
						<Router>
							<Suspense fallback={<div>Loading</div>}>
								<NewBuildCheck>
									<PrintStateContext.Provider value={this.props.printStatus}>
										<ScrollToTop />
										<CoreLayout>
											{this.props.isAuthenticated ? (
												<AuthenticatedRouter />
											) : (
												<UnauthenticatedRouter />
											)}
										</CoreLayout>
									</PrintStateContext.Provider>
								</NewBuildCheck>
							</Suspense>
						</Router>
						<ErrorMessage />
						<SuccessMessage />
						<ProgressMessage />
						<TermsModal
							show={!this.state.isTermOfUseAgreed}
							agreeTermOfUse={this.agreeTermOfUse}
						/>
					</div>
				</ErrorBoundary>
			</Suspense>
		)
	}
}

const mapStateToProps = state => ({
	isAuthenticated: isAuthenticated(state.user),
	termsAccepted: userSelector.getTermsAccepted(state.user),
	organisationDomain: userSelector.getUserOrganisationDomain(state.user),
	printStatus: fileSelector.getPrintStatus(state.file)
})

export default hot(connect(mapStateToProps)(App))
