/**
 * Declare all types and export
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

//reducer
export const RESET_REDUCER = 'RESET_REDUCER'
// Users
export const GET_USERS = 'GET_USERS'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL'

export const CHECK_USER_PASSWORD = 'CHECK_USER_PASSWORD'
export const CHECK_USER_PASSWORD_SUCCESS = 'CHECK_USER_PASSWORD_SUCCESS'
export const CHECK_USER_PASSWORD_FAIL = 'CHECK_USER_PASSWORD_FAIL'

export const CREATE_NEW_ORGANISATION_USER = 'CREATE_NEW_ORGANISATION_USER'
export const CREATE_NEW_ORGANISATION_USER_SUCCESS =
	'CREATE_NEW_ORGANISATION_USER_SUCCESS'
export const CREATE_NEW_ORGANISATION_USER_FAIL =
	'CREATE_NEW_ORGANISATION_USER_FAIL'

export const UPDATE_OTHER_USER = 'UPDATE_OTHER_USER'
export const UPDATE_OTHER_USER_SUCCESS = 'UPDATE_OTHER_USER_SUCCESS'
export const UPDATE_OTHER_USER_FAIL = 'UPDATE_OTHER_USER_FAIL'

export const ACTIVATE_USER = 'ACTIVATE_USER'
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS'
export const ACTIVATE_USER_FAIL = 'ACTIVATE_USER_FAIL'

export const DEACTIVATE_USER = 'DEACTIVATE_USER'
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS'
export const DEACTIVATE_USER_FAIL = 'DEACTIVATE_USER_FAIL'

export const VERIFY_BEARER = 'VERIFY_BEARER'
export const VERIFY_BEARER_SUCCESS = 'VERIFY_BEARER_SUCCESS'
export const VERIFY_BEARER_FAIL = 'VERIFY_BEARER_FAIL'

export const VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD =
	'VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD'
export const VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD_SUCCESS =
	'VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD_SUCCESS'
export const VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD_FAIL =
	'VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD_FAIL'

export const CREATE_TEMP_ORGANISATION_ADMIN = 'CREATE_TEMP_ORGANISATION_ADMIN'
export const CREATE_TEMP_ORGANISATION_ADMIN_SUCCESS =
	'CREATE_TEMP_ORGANISATION_ADMIN_SUCCESS'
export const CREATE_TEMP_ORGANISATION_ADMIN_FAIL =
	'CREATE_TEMP_ORGANISATION_ADMIN_FAIL'

export const UPDATE_BEARER = 'UPDATE_BEARER'

// Authentication
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const UPDATE_LOGIN_FORM = 'UPDATE_LOGIN_FORM'

export const CLEAR_LOGIN_FORM = 'CLEAR_LOGIN_FORM'

//Register

export const UPDATE_REGISTER_FORM = 'UPDATE_REGISTER_FORM'

export const CLEAR_REGISTER_FORM = 'CLEAR_REGISTER_FORM'

//AppState

export const SHOW_ERROR = 'SHOW_ERROR'

export const UPDATE_GUIDE_STEP = 'UPDATE_GUIDE_STEP'

export const CHANGE_APP_STATE = 'CHANGE_APP_STATE'

//Category
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS'
export const GET_ALL_CATEGORIES_FAIL = 'GET_ALL_CATEGORIES_FAIL'

export const GET_OWN_CATEGORIES = 'GET_OWN_CATEGORIES'
export const GET_OWN_CATEGORIES_SUCCESS = 'GET_OWN_CATEGORIES_SUCCESS'
export const GET_OWN_CATEGORIES_FAIL = 'GET_OWN_CATEGORIES_FAIL'

export const UPDATE_ORGANIZATION_OWN_CATEGORIES =
	'UPDATE_ORGANIZATION_OWN_CATEGORIES'
export const UPDATE_ORGANIZATION_OWN_CATEGORIES_SUCCESS =
	'UPDATE_ORGANIZATION_OWN_CATEGORIES_SUCCESS'
export const UPDATE_ORGANIZATION_OWN_CATEGORIES_FAIL =
	'UPDATE_ORGANIZATION_OWN_CATEGORIES_FAIL'

export const SET_ACCESSIBLE_CATEGORIES = 'SET_ACCESSIBLE_CATEGORIES'

export const CREATE_CATEGORY = 'CREATE_CATEGORY'

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'

export const DELETE_CATEGORY = 'DELETE_CATEGORY'

export const UPDATE_CATEGORY_ACTIVE = 'UPDATE_CATEGORY_ACTIVE'

//Section

export const GET_DEFAULT_TEMPLATES = 'GET_DEFAULT_TEMPLATES'
export const GET_DEFAULT_TEMPLATES_SUCCESS = 'GET_DEFAULT_TEMPLATES_SUCCESS'
export const GET_DEFAULT_TEMPLATES_FAIL = 'GET_DEFAULT_TEMPLATES_FAIL'

export const GET_ALL_SECTION_TEMPLATES = 'GET_ALL_SECTION_TEMPLATES'
export const GET_ALL_SECTION_TEMPLATES_SUCCESS =
	'GET_ALL_SECTION_TEMPLATES_SUCCESS'
export const GET_ALL_SECTION_TEMPLATES_FAIL = 'GET_ALL_SECTION_TEMPLATES_FAIL'

export const GET_ALL_SECTION_TEMPLATE_CATEGORIES =
	'GET_ALL_SECTION_TEMPLATE_CATEGORIES'
export const GET_ALL_SECTION_TEMPLATE_CATEGORIES_SUCCESS =
	'GET_ALL_SECTION_TEMPLATE_CATEGORIES_SUCCESS'
export const GET_ALL_SECTION_TEMPLATE_CATEGORIES_FAIL =
	'GET_ALL_SECTION_TEMPLATE_CATEGORIES_FAIL'

export const GET_ALL_SECTION_TEMPLATE_DETAILS =
	'GET_ALL_SECTION_TEMPLATE_DETAILS'
export const GET_ALL_SECTION_TEMPLATE_DETAILS_SUCCESS =
	'GET_ALL_SECTION_TEMPLATE_DETAILS_SUCCESS'
export const GET_ALL_SECTION_TEMPLATE_DETAILS_FAIL =
	'GET_ALL_SECTION_TEMPLATE_DETAILS_FAIL'

export const UPDATE_SECTION_DRAFT = 'UPDATE_SECTION_DRAFT'

export const UPDATE_QUESTION_DRAFT = 'UPDATE_QUESTION_DRAFT'

export const UPDATE_QUESTION_ROW_DRAFT = 'UPDATE_QUESTION_ROW_DRAFT'

export const UPDATE_QUESTION_IMAGE_ROW_DRAFT = 'UPDATE_QUESTION_IMAGE_ROW_DRAFT'

export const UPDATE_QUESTION_COLUMN_DRAFT = 'UPDATE_QUESTION_COLUMN_DRAFT'

export const ADD_QUESTION_ROW_DRAFT = 'ADD_QUESTION_ROW_DRAFT'

export const ADD_QUESTION_ROW_DRAFT_BY_INDEX = 'ADD_QUESTION_ROW_DRAFT_BY_INDEX'

export const ADD_QUESTION_COLUMN_DRAFT_BY_INDEX =
	'ADD_QUESTION_COLUMN_DRAFT_BY_INDEX'

export const ADD_QUESTION_COLUMN_DRAFT = 'ADD_QUESTION_COLUMN_DRAFT'

export const DELETE_QUESTION_ROW_DRAFT = 'DELETE_QUESTION_ROW_DRAFT'

export const DELETE_QUESTION_COLUMN_DRAFT = 'DELETE_QUESTION_COLUMN_DRAFT'

export const ADD_QUESTION = 'ADD_QUESTION'

export const REMOVE_QUESTION = 'REMOVE_QUESTION'

export const COPY_QUESTION = 'COPY_QUESTION'

export const ADD_SECTION = 'ADD_SECTION'

export const ADD_SECTION_BY_INDEX = 'ADD_SECTION_BY_INDEX'

export const ADD_ALL_SECTIONS = 'ADD_ALL_SECTIONS'

export const REMOVE_ALL_SECTIONS = 'REMOVE_ALL_SECTIONS'

export const REMOVE_SECTION = 'REMOVE_SECTION'

export const COPY_SECTION = 'COPY_SECTION'

export const MOVE_SECTION = 'MOVE_SECTION'

export const CREATE_SECTION_TOOLTIP = 'CREATE_SECTION_TOOLTIP'
export const CREATE_SECTION_TOOLTIP_SUCCESS = 'CREATE_SECTION_TOOLTIP_SUCCESS'
export const CREATE_SECTION_TOOLTIP_FAIL = 'CREATE_SECTION_TOOLTIP_FAIL'

export const CREATE_SECTION_TEMPLATE_TOOLTIP = 'CREATE_SECTION_TEMPLATE_TOOLTIP'
export const CREATE_SECTION_TEMPLATE_TOOLTIP_SUCCESS =
	'CREATE_SECTION_TEMPLATE_TOOLTIP_SUCCESS'
export const CREATE_SECTION_TEMPLATE_TOOLTIP_FAIL =
	'CREATE_SECTION_TEMPLATE_TOOLTIP_FAIL'

export const CREATE_METER_TEMPLATE = 'CREATE_METER_TEMPLATE'
export const CREATE_METER_TEMPLATE_SUCCESS = 'CREATE_METER_TEMPLATE_SUCCESS'
export const CREATE_METER_TEMPLATE_FAIL = 'CREATE_METER_TEMPLATE_FAIL'

export const EDIT_METER_TEMPLATE = 'EDIT_METER_TEMPLATE'
export const EDIT_METER_TEMPLATE_SUCCESS = 'EDIT_METER_TEMPLATE_SUCCESS'
export const EDIT_METER_TEMPLATE_FAIL = 'EDIT_METER_TEMPLATE_FAIL'

export const EDIT_METER_TEMPLATE_CATEGORIES = 'EDIT_METER_TEMPLATE_CATEGORIES'
export const EDIT_METER_TEMPLATE_CATEGORIES_SUCCESS =
	'EDIT_METER_TEMPLATE_CATEGORIES_SUCCESS'
export const EDIT_METER_TEMPLATE_CATEGORIES_FAIL =
	'EDIT_METER_TEMPLATE_CATEGORIES_FAIL'

export const EDIT_METER_TEMPLATE_ORGANIZATION =
	'EDIT_METER_TEMPLATE_ORGANIZATION'
export const EDIT_METER_TEMPLATE_ORGANIZATION_SUCCESS =
	'EDIT_METER_TEMPLATE_ORGANIZATION_SUCCESS'
export const EDIT_METER_TEMPLATE_ORGANIZATION_FAIL =
	'EDIT_METER_TEMPLATE_ORGANIZATION_FAIL'

export const UPDATE_ORGANIZATION_METER_CATEGORIES =
	'UPDATE_ORGANIZATION_METER_CATEGORIES'
export const UPDATE_ORGANIZATION_METER_CATEGORIES_SUCCESS =
	'UPDATE_ORGANIZATION_METER_CATEGORIES_SUCCESS'
export const UPDATE_ORGANIZATION_METER_CATEGORIES_FAIL =
	'UPDATE_ORGANIZATION_METER_CATEGORIES_FAIL'

export const EDIT_SECTION_TOOLTIP = 'EDIT_SECTION_TOOLTIP'
export const EDIT_SECTION_TOOLTIP_SUCCESS = 'EDIT_SECTION_TOOLTIP_SUCCESS'
export const EDIT_SECTION_TOOLTIP_FAIL = 'EDIT_SECTION_TOOLTIP_FAIL'

export const DELETE_TOOLTIP = 'DELETE_TOOLTIP'
export const DELETE_TOOLTIP_SUCCESS = 'DELETE_TOOLTIP_SUCCESS'
export const DELETE_TOOLTIP_FAIL = 'DELETE_TOOLTIP_FAIL'

export const DELETE_SECTION = 'DELETE_SECTION'
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS'
export const DELETE_SECTION_FAIL = 'DELETE_SECTION_FAIL'

export const DELETE_ROW = 'DELETE_ROW'
export const DELETE_ROW_SUCCESS = 'DELETE_ROW_SUCCESS'
export const DELETE_ROW_FAIL = 'DELETE_ROW_FAIL'

export const DELETE_COLUMN = 'DELETE_COLUMN'
export const DELETE_COLUMN_SUCCESS = 'DELETE_COLUMN_SUCCESS'
export const DELETE_COLUMN_FAIL = 'DELETE_COLUMN_FAIL'

export const DELETE_QUESTION = 'DELETE_QUESTION'
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL'

export const MOVE_ROW = 'MOVE_ROW'
export const MOVE_COLUMN = 'MOVE_COLUMN'

export const CREATE_QUESTION = 'CREATE_QUESTION'
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS'
export const CREATE_QUESTION_FAIL = 'CREATE_QUESTION_FAIL'

export const CREATE_TEMPLATE_QUESTION = 'CREATE_TEMPLATE_QUESTION'
export const CREATE_TEMPLATE_QUESTION_SUCCESS =
	'CREATE_TEMPLATE_QUESTION_SUCCESS'
export const CREATE_TEMPLATE_QUESTION_FAIL = 'CREATE_TEMPLATE_QUESTION_FAIL'

//Template

export const ADD_CHOSEN_TEMPLATE = 'ADD_CHOSEN_TEMPLATE'

export const CLEAR_CHOSEN_TEMPLATE = 'CLEAR_CHOSEN_TEMPLATE'

export const CHANGE_TEMPLATE_BOARD_SHOW = 'CHANGE_TEMPLATE_BOARD_SHOW'

export const CHANGE_CURRENT_SECTION_INDEX = 'CHANGE_CURRENT_SECTION_INDEX'

export const CREATE_TEMPLATE = 'CREATE_TEMPLATE'
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS'
export const CREATE_TEMPLATE_FAIL = 'CREATE_TEMPLATE_FAIL'

export const EDIT_TEMPLATE = 'EDIT_TEMPLATE'
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS'
export const EDIT_TEMPLATE_FAIL = 'EDIT_TEMPLATE_FAIL'

export const UPDATE_TEMPLATE_DRAFT = 'UPDATE_TEMPLATE_DRAFT'

export const GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES'
export const GET_ALL_TEMPLATES_SUCCESS = 'GET_ALL_TEMPLATES_SUCCESS'
export const GET_ALL_TEMPLATES_FAIL = 'GET_ALL_TEMPLATES_FAIL'

export const GET_ALL_ACCESSIBLE_TEMPLATES = 'GET_ALL_ACCESSIBLE_TEMPLATES'
export const GET_ALL_ACCESSIBLE_TEMPLATES_SUCCESS =
	'GET_ALL_ACCESSIBLE_TEMPLATES_SUCCESS'
export const GET_ALL_ACCESSIBLE_TEMPLATES_FAIL =
	'GET_ALL_ACCESSIBLE_TEMPLATES_FAIL'

//Tag

export const GET_ALL_TAGS = 'GET_ALL_TAGS'
export const GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS'
export const GET_ALL_TAGS_FAIL = 'GET_ALL_TAGS_FAIL'

export const CREATE_TAG = 'CREATE_TAG'
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS'
export const CREATE_TAG_FAIL = 'CREATE_TAG_FAIL'

export const UPDATE_TAG = 'UPDATE_TAG'
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS'
export const UPDATE_TAG_FAIL = 'UPDATE_TAG_FAIL'

export const DELETE_TAG = 'DELETE_TAG'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL'

export const GET_SINGLE_TAG = 'GET_SINGLE_TAG'
export const GET_SINGLE_TAG_SUCCESS = 'GET_SINGLE_TAG_SUCCESS'
export const GET_SINGLE_TAG_FAIL = 'GET_SINGLE_TAG_FAIL'

//Content

export const CREATE_CONTENT = 'CREATE_CONTENT'
export const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS'
export const CREATE_CONTENT_FAIL = 'CREATE_CONTENT_FAIL'

export const UPDATE_CONTENT_DRAFT = 'UPDATE_CONTENT_DRAFT'

export const GET_SINGLE_CONTENT = 'GET_SINGLE_CONTENT'
export const GET_SINGLE_CONTENT_SUCCESS = 'GET_SINGLE_CONTENT_SUCCESS'
export const GET_SINGLE_CONTENT_FAIL = 'GET_SINGLE_CONTENT_FAIL'

export const UPDATE_WHOLE_CONTENT_DRAFT = 'UPDATE_WHOLE_CONTENT_DRAFT'

export const UPDATE_CONTENT = 'UPDATE_CONTENT'
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS'
export const UPDATE_CONTENT_FAIL = 'UPDATE_CONTENT_FAIL'

export const UPDATE_CONTENT_LANGUAGES = 'UPDATE_CONTENT_LANGUAGES'
export const UPDATE_CONTENT_LANGUAGES_SUCCESS =
	'UPDATE_CONTENT_LANGUAGES_SUCCESS'
export const UPDATE_CONTENT_LANGUAGES_FAIL = 'UPDATE_CONTENT_LANGUAGES_FAIL'

export const CLEAR_CONTENT_DRAFT = 'CLEAR_CONTENT_DRAFT'

export const UPDATE_CONTENT_ANSWER = 'UPDATE_CONTENT_ANSWER'

export const AUTO_SAVE_CONTENT = 'AUTO_SAVE_CONTENT'
export const AUTO_SAVE_CONTENT_SUCCESS = 'AUTO_SAVE_CONTENT_SUCCESS'
export const AUTO_SAVE_CONTENT_FAIL = 'AUTO_SAVE_CONTENT_FAIL'

export const AUTO_CREATE_CONTENT = 'AUTO_CREATE_CONTENT'
export const AUTO_CREATE_CONTENT_SUCCESS = 'AUTO_CREATE_CONTENT_SUCCESS'
export const AUTO_CREATE_CONTENT_FAIL = 'AUTO_CREATE_CONTENT_FAIL'
//Survey

export const DELETE_SURVEY = 'DELETE_SURVEY'
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS'
export const DELETE_SURVEY_FAIL = 'DELETE_SURVEY_FAIL'

export const CREATE_SURVEY = 'CREATE_SURVEY'
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS'
export const CREATE_SURVEY_FAIL = 'CREATE_SURVEY_FAIL'

export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const UPDATE_SURVEY_SUCCESS = 'UPDATE_SURVEY_SUCCESS'
export const UPDATE_SURVEY_FAIL = 'UPDATE_SURVEY_FAIL'

export const UPDATE_SURVEY_DRAFT = 'UPDATE_SURVEY_DRAFT'

export const GET_SINGLE_SURVEY = 'GET_SINGLE_SURVEY'
export const GET_SINGLE_SURVEY_SUCCESS = 'GET_SINGLE_SURVEY_SUCCESS'
export const GET_SINGLE_SURVEY_FAIL = 'GET_SINGLE_SURVEY_FAIL'

export const GET_USER_SURVEYS = 'GET_USER_SURVEYS'
export const GET_USER_SURVEYS_SUCCESS = 'GET_USER_SURVEYS_SUCCESS'
export const GET_USER_SURVEYS_FAIL = 'GET_USER_SURVEYS_FAIL'

export const RESET_SURVEY_SUMMARY = 'RESET_SURVEY_SUMMARY'

export const CLEAR_SURVEY_DRAFT = 'CLEAR_SURVEY_DRAFT'

export const UPDATE_WHOLE_SURVEY_DRAFT = 'UPDATE_WHOLE_SURVEY_DRAFT'

export const UPDATE_SURVEY_FILTER = 'UPDATE_SURVEY_FILTER'

export const GET_SURVEY_BY_PUBLIC_LINK = 'GET_SURVEY_BY_PUBLIC_LINK'
export const GET_SURVEY_BY_PUBLIC_LINK_SUCCESS =
	'GET_SURVEY_BY_PUBLIC_LINK_SUCCESS'
export const GET_SURVEY_BY_PUBLIC_LINK_FAIL = 'GET_SURVEY_BY_PUBLIC_LINK_FAIL'

export const GET_SURVEY_BY_PRIVATE_LINK = 'GET_SURVEY_BY_PRIVATE_LINK'
export const GET_SURVEY_BY_PRIVATE_LINK_SUCCESS =
	'GET_SURVEY_BY_PRIVATE_LINK_SUCCESS'
export const GET_SURVEY_BY_PRIVATE_LINK_FAIL = 'GET_SURVEY_BY_PRIVATE_LINK_FAIL'

export const CHECK_PUBLIC_LINK_VALID = 'CHECK_PUBLIC_LINK_VALID'
export const CHECK_PUBLIC_LINK_VALID_SUCCESS = 'CHECK_PUBLIC_LINK_VALID_SUCCESS'
export const CHECK_PUBLIC_LINK_VALID_FAIL = 'CHECK_PUBLIC_LINK_VALID_FAIL'

export const GET_SURVEY_COMPARISON = 'GET_SURVEY_COMPARISON'
export const GET_SURVEY_COMPARISON_SUCCESS = 'GET_SURVEY_COMPARISON_SUCCESS'
export const GET_SURVEY_COMPARISON_FAIL = 'GET_SURVEY_COMPARISON_FAIL'

export const PUBLISH_SURVEY = 'PUBLISH_SURVEY'
export const PUBLISH_SURVEY_SUCCESS = 'PUBLISH_SURVEY_SUCCESS'
export const PUBLISH_SURVEY_FAIL = 'PUBLISH_SURVEY_FAIL'

export const AUTO_SAVE_SURVEY = 'AUTO_SAVE_SURVEY'
export const AUTO_SAVE_SURVEY_SUCCESS = 'AUTO_SAVE_SURVEY_SUCCESS'
export const AUTO_SAVE_SURVEY_FAIL = 'AUTO_SAVE_SURVEY_FAIL'

export const CHANGE_IS_SURVEY_EDITED = 'CHANGE_IS_SURVEY_EDITED'

export const AUTO_CREATE_SURVEY = 'AUTO_CREATE_SURVEY'
export const AUTO_CREATE_SURVEY_SUCCESS = 'AUTO_CREATE_SURVEY_SUCCESS'
export const AUTO_CREATE_SURVEY_FAIL = 'AUTO_CREATE_SURVEY_FAIL'

export const UPDATE_SURVEY_REMINDER = 'UPDATE_SURVEY_REMINDER'

export const SEND_SURVEY_REMINDER = 'SEND_SURVEY_REMINDER'
export const SEND_SURVEY_REMINDER_SUCCESS = 'SEND_SURVEY_REMINDER_SUCCESS'
export const SEND_SURVEY_REMINDER_FAIL = 'SEND_SURVEY_REMINDER_FAIL'

export const UPDATE_ADD_MORE_MAILING_LIST = 'UPDATE_ADD_MORE_MAILING_LIST'

export const SEND_MORE_MAILING_LIST = 'SEND_MORE_MAILING_LIST'
export const SEND_MORE_MAILING_LIST_SUCCESS = 'SEND_MORE_MAILING_LIST_SUCCESS'
export const SEND_MORE_MAILING_LIST_FAIL = 'SEND_MORE_MAILING_LIST_FAIL'

export const UPDATE_SURVEY_REDUCER = 'UPDATE_SURVEY_REDUCER'

export const ADD_VIEWER_TO_SURVEY = 'ADD_VIEWER_TO_SURVEY'
export const ADD_VIEWER_TO_SURVEY_SUCCESS = 'ADD_VIEWER_TO_SURVEY_SUCCESS'
export const ADD_VIEWER_TO_SURVEY_FAIL = 'ADD_VIEWER_TO_SURVEY_FAIL'

export const ADD_EDITOR_TO_SURVEY = 'ADD_EDITOR_TO_SURVEY'
export const ADD_EDITOR_TO_SURVEY_SUCCESS = 'ADD_EDITOR_TO_SURVEY_SUCCESS'
export const ADD_EDITOR_TO_SURVEY_FAIL = 'ADD_EDITOR_TO_SURVEY_FAIL'

export const DELETE_SURVEY_FOR_NORMAL_USER = 'DELETE_SURVEY_FOR_NORMAL_USER'
export const DELETE_SURVEY_FOR_NORMAL_USER_SUCCESS =
	'DELETE_SURVEY_FOR_NORMAL_USER_SUCCESS'
export const DELETE_SURVEY_FOR_NORMAL_USER_FAIL =
	'DELETE_SURVEY_FOR_NORMAL_USER_FAIL'

export const EDIT_SINGLE_SURVEY_LOCAL = 'EDIT_SINGLE_SURVEY_LOCAL'

export const UPDATE_SURVEY_REMOTE = 'UPDATE_SURVEY_REMOTE'

export const CLEAR_SURVEY_REMOTE = 'CLEAR_SURVEY_REMOTE'

export const SURVEY_GET_SURVEY_BY_TITLE = 'SURVEY_GET_SURVEY_BY_TITLE'
export const SURVEY_GET_SURVEY_BY_TITLE_SUCCESS =
	'SURVEY_GET_SURVEY_BY_TITLE_SUCCESS'
export const SURVEY_GET_SURVEY_BY_TITLE_FAIL = 'SURVEY_GET_SURVEY_BY_TITLE_FAIL'
//Questions

export const GET_QUESTION_TYPES = 'GET_QUESTION_TYPES'
export const GET_QUESTION_TYPES_SUCCESS = 'GET_QUESTION_TYPES_SUCCESS'
export const GET_QUESTION_TYPES_FAIL = 'GET_QUESTION_TYPES_FAIL'

//Answer

export const POST_ANSWERS = 'POST_ANSWERS'
export const POST_ANSWERS_SUCCESS = 'POST_ANSWERS_SUCCESS'
export const POST_ANSWERS_FAIL = 'POST_ANSWERS_FAIL'

export const POST_PRINT_OWN_ANSWERS = 'POST_PRINT_OWN_ANSWERS'
export const POST_PRINT_OWN_ANSWERS_SUCCESS = 'POST_PRINT_OWN_ANSWERS_SUCCESS'
export const POST_PRINT_OWN_ANSWERS_FAIL = 'POST_PRINT_OWN_ANSWERS_FAIL'

export const DELETE_ANSWERS = 'DELETE_ANSWERS'
export const DELETE_ANSWERS_SUCCESS = 'DELETE_ANSWERS_SUCCESS'
export const DELETE_ANSWERS_FAIL = 'DELETE_ANSWERS_FAIL'

export const GET_ANSWERS_BY_TOKEN = 'GET_ANSWERS_BY_TOKEN'
export const GET_ANSWERS_BY_TOKEN_SUCCESS = 'GET_ANSWERS_BY_TOKEN_SUCCESS'
export const GET_ANSWERS_BY_TOKEN_FAIL = 'GET_ANSWERS_BY_TOKEN_FAIL'

//Recipients
export const CREATE_MAILING_LIST = 'CREATE_MAILING_LIST'
export const CREATE_MAILING_LIST_SUCCESS = 'CREATE_MAILING_LIST_SUCCESS'
export const CREATE_MAILING_LIST_FAIL = 'CREATE_MAILING_LIST_FAIL'

export const DELETE_MAILING_LIST = 'DELETE_MAILING_LIST'
export const DELETE_MAILING_LIST_SUCCESS = 'DELETE_MAILING_LIST_SUCCESS'
export const DELETE_MAILING_LIST_FAIL = 'DELETE_MAILING_LIST_FAIL'

export const GET_MAILING_LIST = 'GET_MAILING_LIST'
export const GET_MAILING_LIST_SUCCESS = 'GET_MAILING_LIST_SUCCESS'
export const GET_MAILING_LIST_FAIL = 'GET_MAILING_LIST_FAIL'

export const GET_MAILING_LIST_BY_ID = 'GET_MAILING_LIST_BY_ID'
export const GET_MAILING_LIST_BY_ID_SUCCESS = 'GET_MAILING_LIST_BY_ID_SUCCESS'
export const GET_MAILING_LIST_BY_ID_FAIL = 'GET_MAILING_LIST_BY_ID_FAIL'
export const UPDATE_MAILING_LIST = 'UPDATE_MAILING_LIST'
export const UPDATE_MAILING_LIST_SUCCESS = 'UPDATE_MAILING_LIST_SUCCESS'
export const UPDATE_MAILING_LIST_FAIL = 'UPDATE_MAILING_LIST_FAIL'

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAIL = 'CREATE_CUSTOMER_FAIL'

export const CREATE_MULTIPLE_CUSTOMERS = 'CREATE_MULTIPLE_CUSTOMERS'
export const CREATE_MULTIPLE_CUSTOMERS_SUCCESS =
	'CREATE_MULTIPLE_CUSTOMERS_SUCCESS'
export const CREATE_MULTIPLE_CUSTOMERS_FAIL = 'CREATE_MULTIPLE_CUSTOMERS_FAIL'

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAIL = 'UPDATE_CUSTOMER_FAIL'

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_FAIL = 'DELETE_CUSTOMER_FAIL'

export const EDIT_MAILING_LIST = 'EDIT_MAILING_LIST'

//Success
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE'
export const HIDE_SUCCESS_MESSAGE = 'HIDE_SUCCESS_MESSAGE'

//Organisation
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION'
export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS'
export const CREATE_ORGANISATION_FAIL = 'CREATE_ORGANISATION_FAIL'

export const GET_ALL_ORGANISATIONS = 'GET_ALL_ORGANISATIONS'
export const GET_ALL_ORGANISATIONS_SUCCESS = 'GET_ALL_ORGANISATIONS_SUCCESS'
export const GET_ALL_ORGANISATIONS_FAIL = 'GET_ALL_ORGANISATIONS_FAIL'

export const GET_ORGANISATION_BY_ID = 'GET_ORGANISATION_BY_ID'
export const GET_ORGANISATION_BY_ID_SUCCESS = 'GET_ORGANISATION_BY_ID_SUCCESS'
export const GET_ORGANISATION_BY_ID_FAIL = 'GET_ORGANISATION_BY_ID_FAIL'

export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION'
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS'
export const UPDATE_ORGANISATION_FAIL = 'UPDATE_ORGANISATION_FAIL'

export const REQUEST_NEW_ORGANISATION = 'REQUEST_NEW_ORGANISATION'
export const REQUEST_NEW_ORGANISATION_SUCCESS =
	'REQUEST_NEW_ORGANISATION_SUCCESS'
export const REQUEST_NEW_ORGANISATION_FAIL = 'REQUEST_NEW_ORGANISATION_FAIL'

export const ORGANISATION_CHANGE_FIELD = 'ORGANISATION_CHANGE_FIELD'

export const GET_ORGANIZATION_SUB_ORG_STATUS = 'GET_ORGANIZATION_SUB_ORG_STATUS'
export const GET_ORGANIZATION_SUB_ORG_STATUS_SUCCESS =
	'GET_ORGANIZATION_SUB_ORG_STATUS_SUCCESS'
export const GET_ORGANIZATION_SUB_ORG_STATUS_FAIL =
	'GET_ORGANIZATION_SUB_ORG_STATUS_FAIL'

//Results
export const GET_USER_SURVEY_RESULTS = 'GET_USER_SURVEY_RESULTS'
export const GET_USER_SURVEY_RESULTS_SUCCESS = 'GET_USER_SURVEY_RESULTS_SUCCESS'
export const GET_USER_SURVEY_RESULTS_FAIL = 'GET_USER_SURVEY_RESULTS_FAIL'

export const UPDATE_RESULT_FILTER = 'UPDATE_RESULT_FILTER'

export const EDIT_SINGLE_RESULT_LOCAL = 'EDIT_SINGLE_RESULT_LOCAL'

export const RESET_RESULTS_SUMMARY = 'RESET_RESULTS_SUMMARY'

export const RESULT_GET_SURVEY_BY_TITLE = 'RESULT_GET_SURVEY_BY_TITLE'
export const RESULT_GET_SURVEY_BY_TITLE_SUCCESS =
	'RESULT_GET_SURVEY_BY_TITLE_SUCCESS'
export const RESULT_GET_SURVEY_BY_TITLE_FAIL = 'RESULT_GET_SURVEY_BY_TITLE'

//Result Detail
export const GET_RESULT_BY_SURVEY_ID = 'GET_RESULT_BY_SURVEY_ID'
export const GET_RESULT_BY_SURVEY_ID_SUCCESS = 'GET_RESULT_BY_SURVEY_ID_SUCCESS'
export const GET_RESULT_BY_SURVEY_ID_FAIL = 'GET_RESULT_BY_SURVEY_ID_FAIL'

export const GET_RESULT_FILTERS_BY_SURVEY_ID = 'GET_RESULT_FILTERS_BY_SURVEY_ID'
export const GET_RESULT_FILTERS_BY_SURVEY_ID_SUCCESS =
	'GET_RESULT_FILTERS_BY_SURVEY_ID_SUCCESS'
export const GET_RESULT_FILTERS_BY_SURVEY_ID_FAIL =
	'GET_RESULT_FILTERS_BY_SURVEY_ID_FAIL'

export const GET_RESULT_FILTERS_BY_TEMPLATE_ID =
	'GET_RESULT_FILTERS_BY_TEMPLATE_ID'
export const GET_RESULT_FILTERS_BY_TEMPLATE_ID_SUCCESS =
	'GET_RESULT_FILTERS_BY_TEMPLATE_ID_SUCCESS'
export const GET_RESULT_FILTERS_BY_TEMPLATE_ID_FAIL =
	'GET_RESULT_FILTERS_BY_TEMPLATE_ID_FAIL'
export const GET_COMPARISON_BY_SURVEY = 'GET_COMPARISON_BY_SURVEY'
export const GET_COMPARISON_BY_SURVEY_SUCCESS =
	'GET_COMPARISON_BY_SURVEY_SUCCESS'
export const GET_COMPARISON_BY_SURVEY_FAIL = 'GET_COMPARISON_BY_SURVEY_FAIL'

export const GET_COMPARISON_BY_ADVANCE_FILTER =
	'GET_COMPARISON_BY_ADVANCE_FILTER'
export const GET_COMPARISON_BY_ADVANCE_FILTER_SUCCESS =
	'GET_COMPARISON_BY_ADVANCE_FILTER_SUCCESS'
export const GET_COMPARISON_BY_ADVANCE_FILTER_FAIL =
	'GET_COMPARISON_BY_ADVANCE_FILTER_FAIL'

export const GET_COMPARISON_BY_ADVANCE_COMPARISON =
	'GET_COMPARISON_BY_ADVANCE_COMPARISON'
export const GET_COMPARISON_BY_ADVANCE_COMPARISON_SUCCESS =
	'GET_COMPARISON_BY_ADVANCE_COMPARISON_SUCCESS'
export const GET_COMPARISON_BY_ADVANCE_COMPARISON_FAIL =
	'GET_COMPARISON_BY_ADVANCE_COMPARISON_FAIL'
export const GET_COMPARISON_BY_OWN_SURVEY = 'GET_COMPARISON_BY_OWN_SURVEY'
export const GET_COMPARISON_BY_OWN_SURVEY_SUCCESS =
	'GET_COMPARISON_BY_OWN_SURVEY_SUCCESS'
export const GET_COMPARISON_BY_OWN_SURVEY_FAIL =
	'GET_COMPARISON_BY_OWN_SURVEY_FAIL'
export const GET_COMBINE_BY_OWN_SURVEY = 'GET_COMBINE_BY_OWN_SURVEY'
export const GET_COMBINE_BY_OWN_SURVEY_SUCCESS =
	'GET_COMBINE_BY_OWN_SURVEY_SUCCESS'
export const GET_COMBINE_BY_OWN_SURVEY_FAIL = 'GET_COMBINE_BY_OWN_SURVEY_FAIL'

export const UPDATE_RESULT_DETAIL_FILTER = 'UPDATE_RESULT_DETAIL_FILTER'

export const CLEAR_RESULT_DETAIL_FILTER = 'CLEAR_RESULT_DETAIL_FILTER'

export const CHANGE_SECTION_FILTER_ENABLED = 'CHANGE_SECTION_FILTER_ENABLED'

export const CHANGE_SECTION_FILTER = 'CHANGE_SECTION_FILTER'

export const CHANGE_ADVANCE_FILTER = 'CHANGE_ADVANCE_FILTER'

export const GET_SECTION_FILTER_RESULT = 'GET_SECTION_FILTER_RESULT'
export const GET_SECTION_FILTER_RESULT_SUCCESS =
	'GET_SECTION_FILTER_RESULT_SUCCESS'
export const GET_SECTION_FILTER_RESULT_FAIL = 'GET_SECTION_FILTER_RESULT_FAIL'

export const CLEAR_SECTION_FILTER = 'CLEAR_SECTION_FILTER'

export const CLEAR_ADVANCE_FILTER = 'CLEAR_ADVANCE_FILTER'

export const CLEAR_ADVANCE_COMPARISON = 'CLEAR_ADVANCE_COMPARISON'

export const UPDATE_RESULT_DETAIL_STATUS = 'UPDATE_RESULT_DETAIL_STATUS'

export const UPDATE_RESULT_DETAIL = 'UPDATE_RESULT_DETAIL'

export const GET_METER_COMPARISON = 'GET_METER_COMPARISON'
export const GET_METER_COMPARISON_SUCCESS = 'GET_METER_COMPARISON_SUCCESS'
export const GET_METER_COMPARISON_FAIL = 'GET_METER_COMPARISON_FAIL'

export const CLEAR_METER_COMPARISON_RESULT = 'CLEAR_METER_COMPARISON_RESULT'

export const GET_TAG_FILTER_COUNT = 'GET_TAG_FILTER_COUNT'
export const GET_TAG_FILTER_COUNT_SUCCESS = 'GET_TAG_FILTER_COUNT_SUCCESS'
export const GET_TAG_FILTER_COUNT_FAIL = 'GET_TAG_FILTER_COUNT_FAIL'

export const UPDATE_YEAR_FILTER = 'UPDATE_YEAR_FILTER'

// Result detail - organisation template
export const GET_ORGANISATION_RESULT = 'GET_ORGANISATION_RESULT'
export const GET_ORGANISATION_RESULT_SUCCESS = 'GET_ORGANISATION_RESULT_SUCCESS'
export const GET_ORGANISATION_RESULT_FAIL = 'GET_ORGANISATION_RESULT_FAIL'

export const GET_ORGANISATION_SECTION_FILTER = 'GET_ORGANISATION_SECTION_FILTER'
export const GET_ORGANISATION_SECTION_FILTER_SUCCESS =
	'GET_ORGANISATION_SECTION_FILTER_SUCCESS'
export const GET_ORGANISATION_SECTION_FILTER_FAIL =
	'GET_ORGANISATION_SECTION_FILTER_FAIL'

export const GET_ORGANISATION_SECTION_FILTER_COMPARISON =
	'GET_ORGANISATION_SECTION_FILTER_COMPARISON'
export const GET_ORGANISATION_SECTION_FILTER_COMPARISON_SUCCESS =
	'GET_ORGANISATION_SECTION_FILTER_COMPARISON_SUCCESS'
export const GET_ORGANISATION_SECTION_FILTER_COMPARISON_FAIL =
	'GET_ORGANISATION_SECTION_FILTER_COMPARISON_FAIL'

export const GET_ORGANISATION_ADVANCED_FILTER_COMPARISON =
	'GET_ORGANISATION_ADVANCED_FILTER_COMPARISON'
export const GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_SUCCESS =
	'GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_SUCCESS'
export const GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_FAIL =
	'GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_FAIL'

//Result Detail - Survey Answers and Delete
export const GET_SURVEY_ANSWERS = 'GET_SURVEY_ANSWERS'
export const GET_SURVEY_ANSWERS_SUCCESS = 'GET_SURVEY_ANSWERS_SUCCESS'
export const GET_SURVEY_ANSWERS_FAIL = 'GET_SURVEY_ANSWERS_FAIL'
export const DELETE_SURVEY_ANSWER_BY_ID = 'DELETE_SURVEY_ANSWER_BY_ID'
export const DELETE_SURVEY_ANSWER_BY_ID_SUCCESS =
	'DELETE_SURVEY_ANSWER_BY_ID_SUCCESS'
export const DELETE_SURVEY_ANSWER_BY_ID_FAIL = 'DELETE_SURVEY_ANSWER_BY_ID_FAIL'
export const SEARCH_SURVER_ANSWERS_BY_TEXT = 'SEARCH_SURVER_ANSWERS_BY_TEXT'
export const SEARCH_SURVER_ANSWERS_BY_TEXT_SUCCESS =
	'SEARCH_SURVER_ANSWERS_BY_TEXT_SUCCESS'
export const SEARCH_SURVER_ANSWERS_BY_TEXT_FAIL =
	'SEARCH_SURVER_ANSWERS_BY_TEXT_FAIL'

//Preview
export const ADD_PREVIEW_SECTIONS = 'ADD_PREVIEW_SECTIONS'

export const UPDATE_PREVIEW_QUESTION_DRAFT = 'UPDATE_PREVIEW_QUESTION_DRAFT'
//Files
export const GENERATE_PDF = 'GENERATE_PDF'
export const GENERATE_PDF_SUCCESS = 'GENERATE_PDF_SUCCESS'
export const GENERATE_PDF_FAIL = 'GENERATE_PDF_FAIL'

export const GENERATE_SCREENSHOT = 'GENERATE_SCREENSHOT'
export const GENERATE_SCREENSHOT_SUCCESS = 'GENERATE_SCREENSHOT_SUCCESS'
export const GENERATE_SCREENSHOT_FAIL = 'GENERATE_SCREENSHOT_FAIL'

export const GENERATE_EXCEL = 'GENERATE_EXCEL'
export const GENERATE_EXCEL_SUCCESS = 'GENERATE_EXCEL_SUCCESS'
export const GENERATE_EXCEL_FAIL = 'GENERATE_EXCEL_FAIL'

export const UPDATE_PRINT_DATA = 'UPDATE_PRINT_DATA'

export const UPDATE_PRINT_STATUS = 'UPDATE_PRINT_STATUS'

export const CLEAR_PRINT_DATA = 'CLEAR_PRINT_DATA'

export const CLEAR_PRINT_STATUS = 'CLEAR_PRINT_STATUS'

export const UPDATE_DOWNLOAD_STATUS = 'UPDATE_DOWNLOAD_STATUS'

//Notifications
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS'
export const CREATE_NOTIFICATION_FAIL = 'CREATE_NOTIFICATION_FAIL'

export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION'
export const EDIT_NOTIFICATION_SUCCESS = 'EDIT_NOTIFICATION_SUCCESS'
export const EDIT_NOTIFICATION_FAIL = 'EDIT_NOTIFICATION_FAIL'

export const MARK_AS_SEEN_NOTIFICATION = 'MARK_AS_SEEN_NOTIFICATION'
export const MARK_AS_SEEN_NOTIFICATION_SUCCESS =
	'MARK_AS_SEEN_NOTIFICATION_SUCCESS'
export const MARK_AS_SEEN_NOTIFICATION_FAIL = 'MARK_AS_SEEN_NOTIFICATION_FAIL'

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION_FAIL = 'DELETE_NOTIFICATION_FAIL'

export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS'
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS'
export const GET_ALL_NOTIFICATIONS_FAIL = 'GET_ALL_NOTIFICATIONS_FAIL'

export const UPDATE_NOTIFICATION_DRAFT = 'UPDATE_NOTIFICATION_DRAFT'

export const CLEAR_NOTIFICATION_DRAFT = 'CLEAR_NOTIFICATION_DRAFT'

export const GET_UNREAD_NOTIFICATIONS = 'GET_UNREAD_NOTIFICATIONS'
export const GET_UNREAD_NOTIFICATIONS_SUCCESS =
	'GET_UNREAD_NOTIFICATIONS_SUCCESS'
export const GET_UNREAD_NOTIFICATIONS_FAIL = 'GET_UNREAD_NOTIFICATIONS_FAIL'

export const GET_CONTENT_TRANSLATIONS = 'GET_CONTENT_TRANSLATIONS'
export const GET_CONTENT_TRANSLATIONS_SUCCESS =
	'GET_CONTENT_TRANSLATIONS_SUCCESS'
export const GET_CONTENT_TRANSLATIONS_FAIL = 'GET_CONTENT_TRANSLATIONS_FAIL'

export const CREATE_CONTENT_TRANSLATIONS = 'CREATE_CONTENT_TRANSLATIONS'
export const CREATE_CONTENT_TRANSLATIONS_SUCCESS =
	'CREATE_CONTENT_TRANSLATIONS_SUCCESS'
export const CREATE_CONTENT_TRANSLATIONS_FAIL =
	'CREATE_CONTENT_TRANSLATIONS_FAIL'

export const UPDATE_CONTENT_TRANSLATIONS = 'UPDATE_CONTENT_TRANSLATIONS'
export const UPDATE_CONTENT_TRANSLATIONS_SUCCESS =
	'UPDATE_CONTENT_TRANSLATIONS_SUCCESS'
export const UPDATE_CONTENT_TRANSLATIONS_FAIL =
	'UPDATE_CONTENT_TRANSLATIONS_FAIL'

export const CREATE_SECTION_TRANSLATIONS = 'CREATE_SECTION_TRANSLATIONS'
export const CREATE_SECTION_TRANSLATIONS_SUCCESS =
	'CREATE_SECTION_TRANSLATIONS_SUCCESS'
export const CREATE_SECTION_TRANSLATIONS_FAIL =
	'CREATE_SECTION_TRANSLATIONS_FAIL'

export const UPDATE_SECTION_TRANSLATIONS = 'UPDATE_SECTION_TRANSLATIONS'
export const UPDATE_SECTION_TRANSLATIONS_SUCCESS =
	'UPDATE_SECTION_TRANSLATIONS_SUCCESS'
export const UPDATE_SECTION_TRANSLATIONS_FAIL =
	'UPDATE_SECTION_TRANSLATIONS_FAIL'

export const CREATE_METER_TRANSLATIONS = 'CREATE_METER_TRANSLATIONS'
export const CREATE_METER_TRANSLATIONS_SUCCESS =
	'CREATE_METER_TRANSLATIONS_SUCCESS'
export const CREATE_METER_TRANSLATIONS_FAIL = 'CREATE_METER_TRANSLATIONS_FAIL'

export const UPDATE_METER_TRANSLATIONS = 'UPDATE_METER_TRANSLATIONS'
export const UPDATE_METER_TRANSLATIONS_SUCCESS =
	'UPDATE_METER_TRANSLATIONS_SUCCESS'
export const UPDATE_METER_TRANSLATIONS_FAIL = 'UPDATE_METER_TRANSLATIONS_FAIL'

export const CHANGE_TRANSLATION_FIELD = 'CHANGE_TRANSLATION_FIELD'
export const RESET_TRANSLATIONS = 'RESET_TRANSLATIONS'

//Image Bank
export const GET_ALL_IMAGES = 'GET_ALL_IMAGES'
export const GET_ALL_IMAGES_SUCCESS = 'GET_ALL_IMAGES_SUCCESS'
export const GET_ALL_IMAGES_FAIL = 'GET_ALL_IMAGES_FAIL'
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL'
export const NEW_IMAGE_CHANGE_FIELD = 'NEW_IMAGE_CHANGE_FIELD'
export const SET_IMAGE = 'SET_IMAGE'
export const EDIT_IMAGE_FILE = 'EDIT_IMAGE_FILE'
export const EDIT_IMAGE_FILE_SUCCESS = 'EDIT_IMAGE_FILE_SUCCESS'
export const EDIT_IMAGE_FILE_FAIL = 'EDIT_IMAGE_FILE_FAIL'

// Analyzable templates
export const GET_ANALYZABLE_TEMPLATES = 'GET_ANALYZABLE_TEMPLATES'
export const GET_ANALYZABLE_TEMPLATES_SUCCESS =
	'GET_ANALYZABLE_TEMPLATES_SUCCESS'
export const GET_ANALYZABLE_TEMPLATES_FAIL = 'GET_ANALYZABLE_TEMPLATES_FAIL'
export const ANALYZABLE_CHANGE_FIELD = 'ANALYZABLE_CHANGE_FIELD'
export const REMOVE_SELECTED_SUB_ORG = 'REMOVE_SELECTED_SUB_ORG'
export const SELECT_SUB_ORG = 'SELECT_SUB_ORG'
export const SELECT_ALL_SUB_ORGS = 'SELECT_ALL_SUB_ORGS'
export const REMOVE_ALL_SELECTED_SUB_ORGS = 'REMOVE_ALL_SELECTED_SUB_ORGS'
